import {STEP_UPDATE_DATA, START_GUIDE} from './actionTypes';

const initialState = { guideStarted: false, stepId: '', stepIndex: 0, total: 0, title: '' }

export default function(state = initialState, action) {

    switch(action.type)
    {
      case START_GUIDE:
        return { ...state, guideStarted: action.started }
      case STEP_UPDATE_DATA:
          return { ...state, 
            stepId: action.stepId, 
            stepIndex: action.stepIndex + 1, 
            total: action.total, 
            title: action.stepTitle
          }
        default:
          return { ...state };
    }
}
