import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getTranslate } from 'react-localize-redux';
import { TimeIcon } from 'app/components/svg/TimeIcon';
import { StepsIcon } from 'app/components/svg/StepsIcon';

class IntroIcons extends Component {
  render() {
    return (
      <div className='intro-icons' style={this.props.customStyle}>
        {
          (this.props.timetomake !== '') ?
            <div className='intro-icon' style={{ color: this.props.color }}>
                <div className='intro-icon-svg'>
                  <TimeIcon color={this.props.color}/>
                </div>
                <span className={"intro-icon-text " + this.props.className}>
                {this.props.timetomake}
              </span>
            </div> : null
        }
        {
          (!this.props.hideTotalStepsNr) ?
              <div className='intro-icon'
                   style={(this.props.timetomake === '') ?
                       {float: 'left', minWidth: 'unset', color: this.props.color } : { color: this.props.color }}>
              <div className='intro-icon-svg'>
                <StepsIcon color={this.props.color} />
              </div>
              <span className={ "intro-icon-text " + this.props.className }>
                {this.props.stepsNumber} {this.props.translate('steps')}
              </span>
            </div> : null
        }
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    translate: getTranslate(state.locale)
  };
}

export default connect(mapStateToProps)(IntroIcons);