import React, { Component } from "react";
import { connect } from "react-redux";
import { withThemeContext } from "app/context/withThemeContext";
import { withDisplayModeContext } from "app/context/withDisplayModeContext";
import { closeDialog } from "./actions";
import { onlyCloseDialog } from "./actions";
import BasicVideoPlayer from "app/components/instructions/helpers/videoplayer/basicVideoPlayer";
import { CloseIcon } from "app/components/svg/CloseIcon";
import { CSSTransition } from "react-transition-group";
import { getTranslate } from "react-localize-redux";
import GalleryContent from "./components/GalleryContent";
import GalleryLeaveEmailContent from "./components/GalleryLeaveEmailContent";
import GalleryImagesTip from "./GalleryImagesTip";
import "assets/styles/galleryTip.scss";

class GalleryTip extends Component {
  state = {
    animationIndex: 0,
    sliderIndex: -1,
    animate: false,
    textExists: false,
  };

  componentDidUpdate(prevProps) {
    if (this.props.data.isvisible && !prevProps.data.isvisible) {
      let items =
        this.props.data.tip &&
        this.props.data.tip.data &&
        this.props.data.tip.data.items
          ? this.props.data.tip.data.items
          : [];
      this.setState({
        animate: true,
        sliderIndex: 0,
        animationIndex: this.state.animationIndex + 1,
        textExists: items.some((i) => i.text !== ""),
      });
    }
  }

  containerClick = (event) => {
    event.stopPropagation();
  };

  close = () => {
    this.props.dispatch(closeDialog());
    this.setState({ animationIndex: this.state.animationIndex + 1 });
  };

  onlyClose = () => {
    this.props.dispatch(onlyCloseDialog());
    this.setState({ animationIndex: this.state.animationIndex + 1 });
  };

  prev = () => {
    if (this.state.sliderIndex > 0) {
      this.setState({
        sliderIndex: this.state.sliderIndex - 1,
        animate: true,
        animationIndex: this.state.animationIndex + 1,
      });
    }
  };

  next = () => {
    if (this.props.data.tip.data.items.length > this.state.sliderIndex + 1) {
      this.setState({
        sliderIndex: this.state.sliderIndex + 1,
        animate: true,
        animationIndex: this.state.animationIndex + 1,
      });
    }
  };

  finishedAnimation = () => {
    this.setState({ animate: false });
  };

  render() {
    if (!this.state.textExists) {
      return <GalleryImagesTip data={this.props.data} />;
    }

    let items =
      this.props.data.tip &&
      this.props.data.tip.data &&
      this.props.data.tip.data.items
        ? this.props.data.tip.data.items
        : [];
    return (
      <div
        onClick={this.onlyClose}
        className={
          this.props.data.isvisible
            ? "gallery-tip-wrapper popup-open"
            : "gallery-tip-wrapper popup-close"
        }
      >
        <div
          onClick={this.containerClick}
          className={
            this.props.displayModeContext.portrait
              ? "gallery-tip-container gallery-tip-container-portrait"
              : "gallery-tip-container"
          }
          style={{
            flexDirection: this.props.displayModeContext.portrait
              ? "column"
              : "row",
          }}
        >
          {items && items.length > this.state.sliderIndex ? (
            <>
              <div
                className="gallery-tip-image-container"
                style={{
                  backgroundColor: this.props.themeContext.stepBackgroundColor,
                  width: this.props.displayModeContext.portrait
                    ? "100%"
                    : "60%",
                  height: this.props.displayModeContext.portrait
                    ? "60%"
                    : "100%",
                }}
              >
                <CSSTransition
                  in={this.state.animate}
                  onEntered={this.finishedAnimation}
                  timeout={300}
                  classNames="quick-fade-in"
                >
                  <div className="tip-image-step">
                    {items.map((item, index) => (
                      <div
                        key={index}
                        style={{
                          width: index === this.state.sliderIndex ? "100%" : 0,
                          height: index === this.state.sliderIndex ? "100%" : 0,
                        }}
                      >
                        {item.type === 3 || item.type === 4 ? (
                          <BasicVideoPlayer
                            videoUrl={item.url}
                            play={
                              index === this.state.sliderIndex &&
                              this.props.data.isvisible
                            }
                          />
                        ) : (
                          <div
                            className="tip-gallery-image"
                            style={{
                              backgroundImage: "url('" + item.url + "')",
                            }}
                          />
                        )}
                      </div>
                    ))}
                  </div>
                </CSSTransition>
              </div>
              <div
                className="gallery-tip-text-container"
                style={{
                  width: this.props.displayModeContext.portrait ? "" : "40%",
                  height: this.props.displayModeContext.portrait ? "40%" : "",
                }}
              >
                {this.props.data.tip.type === 1 ? (
                  <GalleryContent
                    text={items[this.state.sliderIndex].text}
                    next={this.next}
                    prev={this.prev}
                    close={this.onlyClose}
                    animationIndex={this.state.animationIndex}
                    currentIndex={this.state.sliderIndex}
                    totalItemsNumber={items.length}
                  />
                ) : (
                  <GalleryLeaveEmailContent
                    text={items[this.state.sliderIndex].text}
                    next={this.next}
                    prev={this.prev}
                    close={this.close}
                    animationIndex={this.state.animationIndex}
                    currentIndex={this.state.sliderIndex}
                    totalItemsNumber={items.length}
                  />
                )}
              </div>
            </>
          ) : null}
          <span className="gallery-tip-close" onClick={this.onlyClose}>
            <CloseIcon opacity={1} color="white" />
          </span>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    data: state.gallerytip,
    translate: getTranslate(state.locale),
  };
}

function matchDispatchToProps(dispatch) {
  return { dispatch };
}

export default withDisplayModeContext(
  withThemeContext(connect(mapStateToProps, matchDispatchToProps)(GalleryTip))
);
