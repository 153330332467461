import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withDisplayModeContext } from 'app/context/withDisplayModeContext';
import { withThemeContext } from 'app/context/withThemeContext';
import StepContentText from './StepContentText';
import StepContentLogo from './StepContentLogo';
import Box from '@material-ui/core/Box';
import 'assets/styles/step.scss';
import { CSSTransition } from 'react-transition-group';
import { getQueryVariable } from 'app/utils/utils';
import Gallery from 'app/components/home/components/gallery/Gallery';



class StepGallery extends Component {

  state = {
    rtl: (getQueryVariable('language') === 'heb') ? true : false
  };

  contentTextExists = () => {
    return ((this.props.text !== '' && this.props.text) || (this.props.step.title !== undefined && this.props.step.title !== '' && !this.props.hideStepTitles) ||
      (this.props.extraData && this.props.extraData.extraDataForm));
  };

  render() {

    return (
      <div
        className={this.state.rtl ? 'step step__rtl' : 'step'}
        style={{ background: '#ffffff' }}
      >
        <Box
          display="flex"
          flexDirection={(this.props.displayModeContext.portrait) ? "column" : "row"}
          style={{ height: 'calc(100% - 70px)', width: '100%' }}>
          <Box
            display="flex"
            flexGrow={1}
            style={{
              overflow: 'hidden',
              height: (this.props.displayModeContext.portrait) ? '50%' : '100%',
              width: (this.props.displayModeContext.portrait) ? '' : (100 - parseFloat(this.props.themeContext.stepTextContentPercentage)) + '%',
              backgroundColor: this.props.themeContext.stepBackgroundColor,
              position: (this.props.displayModeContext.portrait) ? "relative" : "relative",
              zIndex: 2
            }}>
            {
              (!this.contentTextExists() && this.props.themeContext?.stepsContentLogoEnabled === 'true') ? <StepContentLogo /> : null
            }

            <CSSTransition in={this.props.isCurrentStep} timeout={300} classNames="quick-fade-in">

              <div
                className={(this.props.displayModeContext.portrait) ?
                  "step-gallery" : "step-gallery step-landscape"}
                style={{
                  backgroundImage: 'url(' + this.props.imageUrl + ')',
                  right: (this.props.displayModeContext.portrait || this.contentTextExists()) ? '' : 0,
                  left: (this.contentTextExists()) ? '' : 0,
                }}
              >
                <div>
                  <Gallery isGalleryStep={true} />
                </div>
              </div>
            </CSSTransition>
          </Box>
          {
            (this.contentTextExists()) ?
              <>
                {<StepContentLogo />}
                <Box
                  style={{
                    overflow: 'hidden',
                    backgroundColor: this.props.themeContext.stepTextBackgroundColor,
                    color: this.props.themeContext.stepTextColor,
                    height: (this.props.displayModeContext.portrait) ? '50%' : '100%',
                    width: (this.props.displayModeContext.portrait) ? '' : this.props.themeContext.stepTextContentPercentage + '%',
                    position: (this.props.displayModeContext.portrait) ? "" : "relative"
                  }
                  }
                >
                  <div
                    className={(this.props.displayModeContext.portrait) ? "" : "step-landscape"}
                    style={{
                      height: '100%',
                      width: '',
                      left: 0,
                      zIndex: 1
                    }}>
                    <StepContentText
                      addToBackground={false}
                      isCurrentStep={this.props.isCurrentStep}
                      stepId={this.props.stepId}
                      text={this.props.text}
                      extraData={this.props.extraData}
                      openAction={this.props.openAction} />
                  </div>
                </Box>
              </> : null
          }
        </Box>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    step: state.step,
    hideStepTitles: state.guide.data.hideStepTitles
  };
}

export default withDisplayModeContext(withThemeContext(connect(mapStateToProps)(StepGallery)));
