import React from 'react';

export const ContactIcon = (props) =>(
<svg xmlns="http://www.w3.org/2000/svg" width="23" height="23" viewBox="0 0 23 23">
    <g fill="none" fillRule="evenodd">
        <path d="M0 0H23V23H0z"/>
        <text fill={props.color}  fillOpacity=".4" fontFamily="Manrope" fontSize="11" fontWeight="600" >
            <tspan x="8.5" y="16.4">?</tspan>
        </text>
        <circle cx="11.5" cy="12.5" r="7.5" stroke={props.color} strokeOpacity=".4" strokeWidth="2"/>
    </g>
</svg>

);