import React, { Component } from "react";
import "assets/styles/intro.scss";
import { withThemeContext } from "app/context/withThemeContext";
import IntroDetails from "./IntroDetails";
import { CSSTransition } from "react-transition-group";

class LandscapeIntro extends Component {
  state = {
    loaded: false,
  };

  componentDidMount() {
    this.setState({ loaded: true });
  }

  render() {
    return (
      <div
        style={{
          width: "100%",
          height: "100%",
          backgroundColor: this.props.themeContext.openingScreenBg
            ? this.props.themeContext.openingScreenBg
            : this.props.themeContext.defaultBackgroundColor,
          color: this.props.themeContext.defaultTextColor,
        }}
      >
        <div className="intro-landscape-half-wrapper">
          <div className="intro-landscape-details-wrapper">
            <div style={{ display: "table-cell", verticalAlign: "middle" }}>
              <IntroDetails
                imageurl={this.props.imageurl}
                name={this.props.name}
                subtitle={this.props.subtitle}
                description={this.props.description}
                timetomake={this.props.timetomake}
                hideTotalStepsNr={this.props.hideTotalStepsNr}
                start={this.props.start}
                stepsNumber={this.props.stepsNumber}
                startButtonTitle={this.props.startButtonTitle}
                goBack={this.props.goBack}
                openNewTab={this.props.openNewTab}
                descriptionClassName="intro-landscape-guide-description direction"
                subtitleClassName="intro-landscape-guide-subtitle direction"
                isFullMode={this.props.fullMode}
              />
            </div>
          </div>
        </div>
        <CSSTransition
          in={this.state.loaded}
          timeout={300}
          classNames="fade-in"
        >
          <div
            className="intro-landscape-half-wrapper"
            style={{
              backgroundImage: "url(" + this.props.imageurl + ")",
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
          ></div>
        </CSSTransition>
      </div>
    );
  }
}

export default withThemeContext(LandscapeIntro);
