import React, { Component } from 'react';
import { connect } from 'react-redux';
import 'assets/styles/materials.scss';
import { withThemeContext } from 'app/context/withThemeContext';
import { getTranslate } from 'react-localize-redux';
import { CSSTransition } from 'react-transition-group';
import { Scrollbar } from 'react-scrollbars-custom';
import MaterialsImagesList from './MaterialsImagesList';
import MaterialsItem from './MaterialsItem';
import Box from '@material-ui/core/Box';
import { withDisplayModeContext } from 'app/context/withDisplayModeContext';

class MaterialsList extends Component {

  state = {
    imageMaterials: false
  };

  componentDidMount() {
    var imageMaterials = true;
    this.props.materials.forEach(item => {
      if (!item.imageUrl || item.imageUrl === '') {
        imageMaterials = false;
      }
    });
    this.setState({ imageMaterials: imageMaterials });
  }

  render() {
    if (this.state.imageMaterials) {
      return (
        <MaterialsImagesList
          isCurrentStep={this.props.isCurrentStep}
          materialsText={this.props.materialsText}
          materials={this.props.materials}
          materialsImageUrl={this.props.materialsImageUrl} />

      );
    }

    return (
      <>
        {
          (this.props.materials && this.props.materials.length > 0 || this.props.materialsImageUrl) ?
            <div className='materials-list'>
              <div style={{ width: '100%', height: '100%' }}>
                <Box
                  display="flex"
                  flexDirection={(this.props.displayModeContext.portrait) ? "column" : "row"}
                  style={{ height: 'calc(100% - 70px)', width: '100%' }}>
                  {this.props.materialsImageUrl ? (<Box
                    display="flex"
                    style={{
                      position: 'relative',
                      overflow: 'hidden',
                      height: (this.props.displayModeContext.portrait) ? '50%' : '100%',
                      width: (this.props.displayModeContext.portrait) ? '' : (100 - parseFloat(this.props.themeContext.stepTextContentPercentage)) + '%',
                      backgroundColor: (!this.props.addToBackground) ? this.props.themeContext.stepBackgroundColor : 'none',
                      position: (this.props.displayModeContext.portrait) ? "" : "relative",
                      zIndex: 2
                    }}

                    flexGrow={1}

                  >
                    <CSSTransition in={Boolean(this.props.materialsImageUrl)} timeout={300} classNames="fade-in">
                      <div className="step-image"
                        style={{
                          backgroundImage: "url(" + this.props.materialsImageUrl + ")",
                          backgroundSize: 'contain',
                          backgroundRepeat: 'no-repeat',
                          backgroundPosition: 'center'
                        }}>
                      </div>
                    </CSSTransition>  </Box>) : null}
                  <Box
                    display="flex"
                    flexGrow={this.props.materialsImageUrl ? 0 : 1}
                    style={
                      {
                        overflow: 'hidden',
                        backgroundColor: this.props.themeContext.stepTextBackgroundColor,
                        color: this.props.themeContext.stepTextColor,
                        height: (this.props.displayModeContext.portrait) ? '50%' : '100%',
                        width: (this.props.displayModeContext.portrait) ? '' : this.props.themeContext.stepTextContentPercentage + '%',
                        position: (this.props.displayModeContext.portrait) ? "" : "relative",
                        
                      }}
                  >
                    <div className='materials-list-wrapper'>
                      <CSSTransition in={this.props.isCurrentStep} timeout={500} classNames="right-left-medium">
                        <div className='materials-header default-title title-font direction'
                          style={{ color: this.props.themeContext.stepTextColor }}>
                          {(this.props.materialsText && this.props.materialsText !== '') ?
                            this.props.materialsText : this.props.translate('materials')}
                        </div>
                      </CSSTransition>
                      <Scrollbar noScrollX style={{height: '90%'}}>
                        {
                          (this.props.materials && this.props.materials.length > 0) ?
                            <CSSTransition in={this.props.isCurrentStep} timeout={500} classNames="right-left-quick">
                              <ul className='materials-list'
                                style={{ color: this.props.themeContext.stepTextColor }}>
                                {
                                  this.props.materials.map((item, index) => (
                                    <MaterialsItem index={index} item={item} key={index} />
                                  ))
                                }
                              </ul>
                            </CSSTransition> : null
                        }
                      </Scrollbar>
                    </div>

                  </Box>
                </Box>

              </div>
            </div> : null
        }
      </>
    );
  }
}

function matchDispatchToProps(dispatch) {
  return { dispatch };
}

function mapStateToProps(state) {
  return {
    translate: getTranslate(state.locale)

  };
}

export default withDisplayModeContext(withThemeContext(connect(mapStateToProps, matchDispatchToProps)(MaterialsList)));
