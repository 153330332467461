import React, { Component } from "react";
import { connect } from "react-redux";
import { withThemeContext } from "app/context/withThemeContext";
import StepImage from "./components/StepImage";
import StepText from "./components/StepText";
import StepWeb from "./components/StepWeb";
import StepSplit from "./components/StepSplit";
import StepMaterials from "./components/StepMaterials";
import StepVideo from "./components/StepVideo";
import Step3DModel from "./components/Step3DModel";
import StepGallery from "./components/StepGallery";
import {
  openGalleryTip,
  openTextTip,
  openContactUs,
  openFormTip,
  openSendPicture,
} from "./actions";
import { getDevice } from "app/utils/utils";
import "assets/styles/step.scss";

const VISIBLE_SLIDES_NUMBER = 8;

class Step extends Component {
  state = {
    isLoaded: false,
    isCurrentStep: false,
    audioPlaying: false,
  };

  componentWillUnmount() {
    if (this.audioPlayer) {
      this.audioPlayer.src = "";
    }
  }

  componentDidUpdate() {
    if (
      this.state.isCurrentStep !==
      (this.props.data.stepId === this.props.step.stepId &&
        this.props.step.guideStarted)
    ) {
      this.setState({
        isCurrentStep:
          this.props.data.stepId === this.props.step.stepId &&
          this.props.step.guideStarted,
      });
      if (this.state.audioPlaying) {
        this.audioPlayer.pause();
        this.setState({ audioPlaying: false });
      }
    }
    let allVideo = document.querySelectorAll(".video-gallery");
    let allImg = document.querySelectorAll(".playImg");
    if (allVideo.length !== 0) {
      allVideo.forEach(function (videoGallery) {
        videoGallery.pause();
      });
      allImg.forEach(function (image) {
        image.style.visibility = "visible";
      });
    }
  }

  showStepContent = (step) => {
    var windowWidth = window.innerWidth;
    var device = getDevice(windowWidth);
    var videoUrl = step.url;
    if (device === "Tablet") {
      videoUrl = step.urlTablet ? step.urlTablet : step.url;
    } else if (device === "Mobile") {
      videoUrl = step.urlMobile ? step.urlMobile : step.url;
    }

    if (
      step.extraData &&
      step.extraData.extraDataQuestion &&
      step.extraData.extraDataQuestion.text !== ""
    ) {
      return (
        <StepSplit
          isCurrentStep={this.state.isCurrentStep}
          imageUrl={step.url}
          data={step.extraData.extraDataQuestion}
          fullMode={this.props.fullMode}
          openAction={this.openAction}
        />
      );
    }
    if (
      this.props.data.extraData &&
      this.props.data.extraData.extraDataMaterials
    ) {
      return (
        <StepMaterials
          isCurrentStep={this.state.isCurrentStep}
          imageUrl={step.url}
          materials={step.extraData.extraDataMaterials.materials}
          materialsImageUrl={step.url}
          materialsText={step.title}
          openAction={this.openAction}
        />
      );
    }

    switch (step.type) {
      case 1:
        if (!step.isDominantText) {
          return (
            <StepImage
              addToBackground={step.addToBackground}
              imageUrl={step.url}
              stepId={step.stepId}
              text={this.props.data.text}
              tips={this.props.data.tips}
              contentTips={this.props.data.contentTips}
              isCurrentStep={this.state.isCurrentStep}
              extraData={step.extraData}
              openAction={this.openAction}
            />
          );
        } else {
          return (
            <StepText
              imageUrl={step.url}
              text={step.text}
              stepId={step.stepId}
              contentTips={this.props.data.contentTips}
              isCurrentStep={this.state.isCurrentStep}
              extraData={step.extraData}
              openAction={this.openAction}
            />
          );
        }
      case 2:
        return (
          <StepImage
            addToBackground={"step.addToBackground"}
            imageUrl={step.url}
            stepId={step.stepId}
            text={this.props.data.text}
            tips={this.props.data.tips}
            contentTips={this.props.data.contentTips}
            isCurrentStep={this.state.isCurrentStep}
            openAction={this.openAction}
          />
        );
      case 3:
        return (
          <StepVideo
            autoPlay={step.autoPlay}
            videoUrl={videoUrl}
            stepId={step.stepId}
            playLoop={true}
            text={this.props.data.text}
            tips={this.props.data.tips}
            contentTips={this.props.data.contentTips}
            isCurrentStep={
              this.props.data.stepId === this.props.step.stepId &&
              this.props.step.guideStarted
            }
            openAction={this.openAction}
            extraData={step.extraData}
            enableControls={true}
            muted={this.props.data.muted}
          />
        );
      case 4:
        return (
          <StepVideo
            autoPlay={step.autoPlay}
            videoUrl={videoUrl}
            stepId={step.stepId}
            playLoop={false}
            text={this.props.data.text}
            isCurrentStep={
              this.props.data.stepId === this.props.step.stepId &&
              this.props.step.guideStarted
            }
            tips={this.props.data.tips}
            contentTips={this.props.data.contentTips}
            openAction={this.openAction}
            extraData={step.extraData}
            enableControls={true}
            muted={this.props.data.muted}
          />
        );
      case 5:
        return (
          <StepWeb
            webUrl={step.url}
            stepId={step.stepId}
            text={this.props.data.text}
            isCurrentStep={this.state.isCurrentStep}
            tips={this.props.data.tips}
            contentTips={this.props.data.contentTips}
            openAction={this.openAction}
          />
        );
      case 6:
        return (
          <Step3DModel
            videoUrl={step.url}
            stepId={step.stepId}
            playLoop={true}
            text={this.props.data.text}
            tips={this.props.data.tips}
            contentTips={this.props.data.contentTips}
            isCurrentStep={this.state.isCurrentStep}
            openAction={this.openAction}
            extraData={step.extraData}
            enableControls={false}
          />
        );
      case 7:
        return (
          <Step3DModel
            videoUrl={step.url}
            stepId={step.stepId}
            playLoop={false}
            text={this.props.data.text}
            isCurrentStep={this.state.isCurrentStep}
            tips={this.props.data.tips}
            contentTips={this.props.data.contentTips}
            openAction={this.openAction}
            extraData={step.extraData}
            enableControls={true}
          />
        );
      case 10:
        return (
          <StepGallery
            addToBackground={step.addToBackground}
            imageUrl={step.url}
            stepId={step.stepId}
            text={this.props.data.text}
            contentTips={this.props.data.contentTips}
            isCurrentStep={this.state.isCurrentStep}
            openAction={this.openAction}
          />
        );
      default:
        break;
    }
  };

  openAction = (item, index, type) => {
    switch (type) {
      case 1:
        this.props.dispatch(openTextTip(item, index));
        return;
      case 2:
        this.props.dispatch(openGalleryTip(item, index));
        return;
      case 3:
        this.props.dispatch(openContactUs());
        return;
      case 6:
        this.playAudio(item.data.url);
        return;
      case 7:
        this.props.dispatch(
          openFormTip(this.props.data.extraData.extraDataTipForm, index)
        );
        return;
      case 8:
        this.props.dispatch(openSendPicture());
        return;
      default:
        return;
    }
  };

  playAudio = (url) => {
    this.audioPlayer.pause();
    if (this.audioPlayer.src === url && this.state.audioPlaying) {
      this.setState({ audioPlaying: false });
    } else {
      this.audioPlayer.src = url;
      this.audioPlayer.play();
      this.setState({ audioPlaying: true });
    }
  };

  shouldStepBeVisible = () => {
    // If the step was already loaded and we are outside the steps, allow for it to be visible
    if (this.props.step.stepId === -1 && this.state.isLoaded) {
      return true;
    }

    // Allow only some of the steps to be visible
    // The visible steps are the steps around our current position
    // This is done for better performance and memory management
    let shouldBeVisible =
      Math.abs(this.props.step.stepId - this.props.data.stepId) <
      VISIBLE_SLIDES_NUMBER / 2;

    return shouldBeVisible;
  };

  render() {
    return (
      <>
        {this.shouldStepBeVisible() ? (
          <>
            <audio ref={(audioPlayer) => (this.audioPlayer = audioPlayer)} />
            {this.showStepContent(this.props.data)}
          </>
        ) : null}
      </>
    );
  }
}

function matchDispatchToProps(dispatch) {
  return { dispatch };
}

function mapStateToProps(state) {
  return {
    step: state.step,
  };
}

export default withThemeContext(
  connect(mapStateToProps, matchDispatchToProps)(Step)
);
