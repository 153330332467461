import React, { Component } from 'react';
import { connect } from 'react-redux';
import 'assets/styles/step.scss';
import { withThemeContext } from 'app/context/withThemeContext';
import MaterialsList from './materials/MaterialsList';
import StepContentLogo from './StepContentLogo';

class StepMaterials extends Component {

  render() {

    return (
      <>
        <div
          className='step'
          style={{ backgroundColor: this.props.themeContext.stepTextBackgroundColor }}>
          <StepContentLogo />
          <MaterialsList
            materials={this.props.materials}
            materialsText={this.props.materialsText}
            materialsImageUrl={this.props.materialsImageUrl}
            isCurrentStep={this.props.isCurrentStep ||
              (this.props.guide.started && this.props.guide.currentStepId === -1)} />
        </div>
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    guide: state.guide
  };
}

export default
  withThemeContext(
    connect(mapStateToProps)(StepMaterials));