import React, { Component } from "react";
import { connect } from "react-redux";
import { getTranslate } from "react-localize-redux";
import { withDisplayModeContext } from "app/context/withDisplayModeContext";
import { withThemeContext } from "app/context/withThemeContext";
import { strToRealTime } from "app/utils/utils";
import Box from "@material-ui/core/Box";
import LandscapeIntro from "./LandscapeIntro";
import IntroDetails from "./IntroDetails";
import { CSSTransition } from "react-transition-group";
import GuideTopbar from "app/components/controls/guideTopbar/GuideTopbar";
import "assets/styles/intro.scss";

class Intro extends Component {
  state = {
    loaded: false,
  };

  componentDidMount() {
    this.setState({ loaded: true });
  }

  render() {
    let timeToMake = strToRealTime(
      this.props.timetomake,
      this.props.translate("shortHours"),
      this.props.translate("hour"),
      this.props.translate("hours"),
      this.props.translate("minutes")
    );

    if (!this.props.displayModeContext.portrait) {
      return (
        <div
          style={{
            width: "100%",
            height: "100%",
            position: "absolute",
            zIndex: "100",
            overflow: "hidden",
          }}
        >
          <LandscapeIntro
            imageurl={this.props.imageurl}
            name={this.props.name}
            subtitle={this.props.subtitle}
            description={this.props.description}
            timetomake={timeToMake}
            hideTotalStepsNr={this.props.hideTotalStepsNr}
            start={this.props.start}
            stepsNumber={this.props.stepsNumber}
            goBack={this.props.goBack}
            openNewTab={this.props.openNewTab}
            startButtonTitle={this.props.startButtonTitle}
            fullMode={this.props.fullMode}
          />
        </div>
      );
    }

    return (
      <div
        style={{
          width: "100%",
          height: "100%",
          overflow: "hidden",
          position: "absolute",
          zIndex: "100",
          backgroundColor: this.props.themeContext.openingScreenBg
            ? this.props.themeContext.openingScreenBg
            : this.props.themeContext.defaultBackgroundColor,
          color: this.props.themeContext.defaultTextColor,
        }}
      >
        <Box
          display="flex"
          flexDirection="column"
          flexGrow={1}
          style={{ height: "100%" }}
        >
          {this.props.displayModeContext.portrait ? (
            <Box>
              <GuideTopbar
                goBack={this.props.goBack}
                close={this.props.close}
              />
            </Box>
          ) : null}
          <CSSTransition
            in={this.state.loaded}
            timeout={300}
            classNames="fade-in"
          >
            <Box
              style={{
                height: "40%",
                backgroundImage: "url(" + this.props.imageurl + ")",
                backgroundSize: "cover",
                backgroundPosition: "center",
              }}
            ></Box>
          </CSSTransition>
          <Box
            className="intro-landscape-details-box"
            display="flex"
            flexGrow={1}
            style={{
              display: "flex",
              flex: 1,
            }}
          >
            <div className="intro-landscape-details-wrapper">
              <div style={{ display: "table-cell", verticalAlign: "middle", paddingBottom: "30px" }}>
                <IntroDetails
                  imageurl={this.props.imageurl}
                  name={this.props.name}
                  subtitle={this.props.subtitle}
                  description={this.props.description}
                  timetomake={timeToMake}
                  hideTotalStepsNr={this.props.hideTotalStepsNr}
                  start={this.props.start}
                  stepsNumber={this.props.stepsNumber}
                  startButtonTitle={this.props.startButtonTitle}
                  openNewTab={this.props.openNewTab}
                  hideLogo={true}
                  descriptionClassName="intro-portrait-guide-description direction"
                  subtitleClassName="intro-portrait-guide-subtitle direction"
                />
              </div>
            </div>
          </Box>
        </Box>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    translate: getTranslate(state.locale),
  };
}

export default connect(mapStateToProps)(
  withDisplayModeContext(withThemeContext(Intro))
);
