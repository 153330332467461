import React, { Component } from "react";
import { connect } from "react-redux";
import { getTranslate } from "react-localize-redux";
import { Scrollbar } from "react-scrollbars-custom";
import { withThemeContext } from "app/context/withThemeContext";
import NextButton from "app/components/controls/buttons/NextButton";
import PrevButton from "app/components/controls/buttons/PrevButton";
import { CSSTransition } from "react-transition-group";

class GalleryContent extends Component {
  state = {
    animate: false,
  };

  componentDidUpdate(prevProps) {
    if (this.props.animationIndex !== prevProps.animationIndex) {
      this.setState({ animate: true });
    }
  }

  finishedAnimation = () => {
    this.setState({ animate: false });
  };

  render() {
    return (
      <>
        <CSSTransition
          in={this.state.animate}
          onEntered={this.finishedAnimation}
          timeout={300}
          classNames="fade-in"
        >
          <div className="gallery-tip-text-wrapper">
            <Scrollbar noScrollX>
              <div className="gallery-tip-text direction">
                {this.props.text}
              </div>
            </Scrollbar>
          </div>
        </CSSTransition>
        {this.props.totalItemsNumber > 1 ? (
          <div className="gallery-tip-buttons">
            <PrevButton
              visible={
                this.props.totalItemsNumber > 1 && this.props.currentIndex !== 0
              }
              click={this.props.prev}
            />
            <NextButton
              visible={
                this.props.totalItemsNumber - 1 > this.props.currentIndex
              }
              click={this.props.next}
            />
          </div>
        ) : (
          <button
            onClick={this.props.close}
            className={"default-main-button gallery-tip-single-button"}
            style={{
              backgroundColor: this.props.themeContext.buttonBackgroundColor,
              color: this.props.themeContext.buttonTextColor,
            }}
          >
            <span
              style={{
                textTransform: "uppercase",
                color: this.props.themeContext.buttonTextColor,
              }}
            >
              {this.props.translate("gotIt")}
            </span>
          </button>
        )}
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    translate: getTranslate(state.locale),
  };
}

export default withThemeContext(connect(mapStateToProps)(GalleryContent));
