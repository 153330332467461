export function getIntroSlidesNumber(data, isHideStart) {
  let introSlidesNumber = 1;
  //check introduction slider number
  if (
    !data.materials ||
    (data.materials.length === 0 &&
      (!data.materialsImageUrl || data.materialsImageUrl.length === 0)) ||
    isHideStart
  ) {
    introSlidesNumber--;
  }
  return introSlidesNumber;
}
