import React, { Component } from "react";
import { connect } from "react-redux";
import "assets/styles/materials.scss";
import { withThemeContext } from "app/context/withThemeContext";
import { getTranslate } from "react-localize-redux";
import MaterialsImageItem from "./MaterialsImageItem";
import { CSSTransition } from "react-transition-group";
import { Scrollbar } from "react-scrollbars-custom";
import Box from "@material-ui/core/Box";
import { withDisplayModeContext } from "app/context/withDisplayModeContext";

class MaterialsImagesList extends Component {
  render() {
    return (this.props.materials && this.props.materials.length > 0) ||
      this.props.materialsImageUrl ? (
      <Box
        display="flex"
        flexDirection={
          this.props.displayModeContext.portrait ? "column" : "row"
        }
        style={{ height: "calc(100% - 70px)", width: "100%" }}
      >
        {this.props.materialsImageUrl ? (
          <Box
            display="flex"
            style={{
              position: "relative",
              overflow: "hidden",
              height: this.props.displayModeContext.portrait ? "50%" : "100%",
              width: this.props.displayModeContext.portrait
                ? ""
                : 100 -
                  parseFloat(
                    this.props.themeContext.stepTextContentPercentage
                  ) +
                  "%",
              backgroundColor: !this.props.addToBackground
                ? this.props.themeContext.stepBackgroundColor
                : "none",
              position: this.props.displayModeContext.portrait
                ? ""
                : "relative",
              zIndex: 2,
            }}
            flexGrow={1}
          >
            <CSSTransition
              in={this.props.materialsImageUrl}
              timeout={300}
              classNames="fade-in"
            >
              <div
                className="step-image"
                style={{
                  backgroundImage: "url(" + this.props.materialsImageUrl + ")",
                  backgroundSize: "contain",
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "center",
                }}
              ></div>
            </CSSTransition>{" "}
          </Box>
        ) : null}

        <Box
          display="flex"
          flexGrow={this.props.materialsImageUrl ? 0 : 1}
          style={{
            overflow: "hidden",
            backgroundColor: this.props.themeContext.stepTextBackgroundColor,
            color: this.props.themeContext.stepTextColor,
            height: this.props.displayModeContext.portrait ? "50%" : "100%",
            width: this.props.displayModeContext.portrait
              ? ""
              : this.props.themeContext.stepTextContentPercentage + "%",
            position: this.props.displayModeContext.portrait ? "" : "relative",
          }}
        >
          <div className="materials-images-list-wrapper">
            <CSSTransition
              in={this.props.isCurrentStep}
              timeout={500}
              classNames="right-left-medium"
            >
              <div
                className="materials-images-header default-title title-font direction"
                style={{ color: this.props.themeContext.stepTextColor }}
              >
                {this.props.materialsText && this.props.materialsText !== ""
                  ? this.props.materialsText
                  : this.props.translate("materials")}
              </div>
            </CSSTransition>
            <Scrollbar noScrollX>
              {this.props.materials && this.props.materials.length > 0 ? (
                <CSSTransition
                  in={this.props.isCurrentStep}
                  timeout={500}
                  classNames="right-left-quick"
                >
                  <div className="direction" style={{ paddingBottom: "20px" }}>
                    {this.props.materials && this.props.materials.length > 0
                      ? this.props.materials.map((item, index) => (
                          <MaterialsImageItem
                            index={index}
                            item={item}
                            key={index}
                            hasimagecovermaterial={
                              this.props.materialsImageUrl ? true : false
                            }
                          />
                        ))
                      : null}
                  </div>
                </CSSTransition>
              ) : null}
            </Scrollbar>
          </div>
        </Box>
      </Box>
    ) : null;
  }
}

function matchDispatchToProps(dispatch) {
  return { dispatch };
}

function mapStateToProps(state) {
  return {
    translate: getTranslate(state.locale),
  };
}

export default withDisplayModeContext(
  withThemeContext(
    connect(mapStateToProps, matchDispatchToProps)(MaterialsImagesList)
  )
);
