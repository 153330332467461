import React, { Component } from "react";
import { connect } from "react-redux";
import { withThemeContext } from "app/context/withThemeContext";
import "assets/styles/videoStep.scss";
import { withDisplayModeContext } from "app/context/withDisplayModeContext";
import "rc-slider/assets/index.css";
import Box from "@material-ui/core/Box";
import StepOverlays from "./overlays/StepOverlays";
import StepContentText from "./StepContentText";
import StepContentLogo from "./StepContentLogo";
import { CSSTransition } from "react-transition-group";

class StepVideo extends Component {
  state = {
    playing: true,
    error: false,
  };

  componentDidUpdate(prevProps) {
    if (
      this.props.videostep.stepId !== prevProps.videostep.stepId &&
      this.video
    ) {
      if (this.props.videostep.stepId === this.props.stepId) {
        if (this.props.autoPlay) {
          this.video.play();
        }
      } else {
       this.video.pause();
      }
    }

    if (this.props.isCurrentStep) {
      document.body.classList.add("hideProgress");
    } else {
      document.body.classList.remove("hideProgress");
    }
  }

  componentWillUnmount() {
    if (this.player) {
      this.player.src = "";
    }
    if (!this.props.isCurrentStep) {
      document.body.classList.remove("hideProgress");
    }
  }

  contentTextExists = () => {
    return (
      this.props.text !== "" ||
      (this.props.extraData && this.props.extraData.extraDataForm)
    );
  };

  render() {
    return (
      <div className="step" style={{ background: "#ffffff" }}>
        <Box
          display="flex"
          flexDirection={
            this.props.displayModeContext.portrait ? "column" : "row"
          }
          style={{ height: "calc(100% - 70px)" }}
        >
          <Box
            flexGrow={1}
            style={{
              overflow: "hidden",
              position: "relative",
              height: this.props.displayModeContext.portrait ? "50%" : "100%",
              width: this.props.displayModeContext.portrait
                ? "100%"
                : 100 -
                  parseFloat(
                    this.props.themeContext.stepTextContentPercentage
                  ) +
                  "%",
              backgroundColor: this.props.themeContext.stepBackgroundColor,
            }}
          >
            <CSSTransition
              in={this.props.isCurrentStep}
              timeout={300}
              classNames="quick-fade-in"
            >
              <video
                ref={(video) => (this.video = video)}
                src={this.props.videoUrl}
                style={{
                  width: "100%",
                  height: "100%",
                  background: this.props.themeContext.stepBackgroundColor,
                }}
                loop={this.props.playLoop}
                playsInline
                controls={this.props.enableControls}
                muted={
                  this.props.muted
                    ? this.props.muted
                    : this.props.videostep.stepId === 1
                }
              />
            </CSSTransition>
            <StepOverlays
              tips={this.props.tips}
              stepId={this.props.stepId}
              openAction={this.props.openAction}
            />
          </Box>
          {this.contentTextExists() ? (
            <>
              <StepContentLogo />
              <Box
                flexGrow={1}
                style={{
                  backgroundColor:
                    this.props.themeContext.stepTextBackgroundColor,
                  color: this.props.themeContext.stepTextColor,
                  height: this.props.displayModeContext.portrait
                    ? "50%"
                    : "100%",
                  width: this.props.displayModeContext.portrait
                    ? "100%"
                    : this.props.themeContext.stepTextContentPercentage + "%",
                }}
              >
                <div style={{ height: "100%" }}>
                  <StepContentText
                    isCurrentStep={this.props.isCurrentStep}
                    stepId={this.props.stepId}
                    extraData={this.props.extraData}
                    text={this.props.text}
                    contentTips={this.props.contentTips}
                    openAction={this.props.openAction}
                  />
                </div>
              </Box>
            </>
          ) : null}
        </Box>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    videostep: state.step,
  };
}

function matchDispatchToProps(dispatch) {
  return { dispatch };
}

export default withThemeContext(
  withDisplayModeContext(
    connect(mapStateToProps, matchDispatchToProps)(StepVideo)
  )
);
