import React, { Component } from "react";
import { connect } from "react-redux";
import { withThemeContext } from "app/context/withThemeContext";
import { withDisplayModeContext } from "app/context/withDisplayModeContext";
import { withGeneralContext } from "app/context/withGeneralContext";
import Box from "@material-ui/core/Box";
import Carousel from "nuka-carousel";
import StepMaterials from "app/components/instructions/step/components/StepMaterials";
import Step from "app/components/instructions/step/Step";
import TakePhoto from "app/components/instructions/takephoto/TakePhoto";
import Finish from "app/components/instructions/finish/Finish";
import GuideNavigation from "app/components/controls/guideNavigation/GuideNavigation";
import GalleryTip from "app/components/instructions/popups/gallerytip/GalleryTip";
import TextTip from "app/components/instructions/popups/texttip/TextTip";
import {
  beginningSlide,
  endSlide,
  slide,
  updateStepData,
  navigateBack,
  openFinish,
} from "./actions";
import { getIntroSlidesNumber } from "app/utils/guideUtils";
import ProjectAnalyticsHelper from "app/helpers/projectAnalyticsHelper";
import { getQueryVariable } from "app/utils/utils";
import GuideTopbar from "app/components/controls/guideTopbar/GuideTopbar";
import ContactForm from "app/components/controls/contactForm/ContactForm";
import "assets/styles/instructions.scss";
import FormTip from "../../instructions/popups/formtip/FormTip";
import SendFormMessage from "app/components/general/sendFormMessage/sendFormMessage";

class Instructions extends Component {
  introSlidesNumber = 0;

  state = {
    showNavigation: true,
    carouselBlocks: null,
    slideIndex: 0,
    dragSwipCarousel: true,
  };

  componentDidMount() {
    this.initialStepDone = false;
    this.customLanguages = getQueryVariable("languages");

    this.updateIntroSlidesNumber();
    this.buildCarousel();

    // Subscribe to on close event
    window.addEventListener("beforeunload", (ev) => {
      this.props.dispatch(
        updateStepData(-1, -1, -1, "", 0, 0, false, "", "", null, false)
      );
      ProjectAnalyticsHelper.saveAndReport();
    });
  }

  updateIntroSlidesNumber = () => {
    if (this.props.instructions.navigationBackStepId === -1) {
      this.introSlidesNumber = getIntroSlidesNumber(
        this.props.data,
        this.props.instructions.hideStart
      );
    } else {
      this.introSlidesNumber = 0;
    }
  };

  componentDidUpdate(prevProps) {
    // Check if we have a current step index that we need to initially load
    // This is mainly used for the back navigation
    if (!this.initialStepDone && this.props.instructions.currentStepIndex > 0) {
      // Go to the requested initial step
      if (
        this.props.instructions.currentStepIndex > 0 &&
        this.props.instructions.currentStepIndex <
          this.props.data.lessonSteps.length
      ) {
        this.props.dispatch(slide());
        this.setState({
          slideIndex:
            this.props.instructions.currentStepIndex + this.introSlidesNumber,
        });
      }
      this.initialStepDone = true;
      this.loadFirstStepData();
    }

    // Check if we received a custom init with initial step to go to
    else if (!this.initialStepDone && this.props.initialstepid) {
      let stepId = parseInt(this.props.initialstepid, 10);

      // Go to the requested initial step
      if (stepId > 0 && stepId <= this.props.data.lessonSteps.length) {
        this.props.dispatch(slide());
        this.setState({ slideIndex: stepId + this.introSlidesNumber - 1 });
      }
    }

    // Load first step data
    if (!this.initialStepDone) {
      this.initialStepDone = true;
      this.loadFirstStepData();
    }

    // Check if the split decision was made and steps were changed
    if (
      this.props.instructions.navigationBackStepId !==
      prevProps.instructions.navigationBackStepId
    ) {
      this.updateIntroSlidesNumber();
      this.buildCarousel();

      // This is a fix for an issue with the nuka carousel
      // When rebuilding the carousel, sometimes the carousel is not refreshing its state and the
      // previous slide index is used, even though the amount of the slides was changed and the
      // expected slide index should be lastChangedStep
      this.carousel.goToSlide(this.props.instructions.lastChangedStep);
      this.carousel.setState({
        currentSlide: this.props.instructions.lastChangedStep,
      });
      this.onSlideChange(-1, this.props.instructions.lastChangedStep);
    }

    // Check if we need to jump to a specific custom slide
    if (
      prevProps.instructions.lastChangedStep !==
        this.props.instructions.lastChangedStep &&
      this.props.instructions.lastChangedStep >= 0
    ) {
      this.setState({
        slideIndex:
          this.introSlidesNumber + this.props.instructions.lastChangedStep,
      });
    }
  }

  loadFirstStepData = () => {
    // Check if we don't have intro slides.
    // In this case  we need to show the first slide, so we need to dispatch the first slide data
    if (
      this.introSlidesNumber === 0 &&
      this.props.guide.data.lessonSteps.length > 0
    ) {
      let currStep = this.props.guide.data.lessonSteps[0];
      this.props.dispatch(
        updateStepData(
          currStep.stepId,
          0,
          currStep.type,
          currStep.stepGroup,
          this.props.guide.data.lessonSteps.length,
          this.props.guide.data.allSteps.length,
          this.props.instructions.hideEnd,
          currStep.title,
          currStep.text,
          currStep.extraData,
          currStep.isDominantText
        )
      );
      ProjectAnalyticsHelper.startRecording(currStep.stepId);
    }
  };

  nextSlide = () => {
    if (
      !this.props.instructions.hideEnd &&
      this.state.slideIndex === this.carousel.state.slideCount - 1
    ) {
      ProjectAnalyticsHelper.finishProject();
      this.props.dispatch(openFinish());
    } else {
      this.carousel.nextSlide();
    }
  };

  prevSlide = () => {
    if (
      this.props.instructions.navigationBackStepId !== -1 &&
      this.carousel.state.currentSlide === 0
    ) {
      this.props.dispatch(
        navigateBack(this.props.instructions.navigationBackStepId)
      );
    } else if (this.state.slideIndex > 0) {
      this.carousel.previousSlide();
    } else if (this.props.backToStart) {
      this.props.hideIntro(this.carousel.state.currentSlide);
      this.props.backToStart();
    } else if (this.props.goBack) {
      this.props.goBack();
    }
  };

  onSlideChange = (prevSlide, currentSlide) => {
    // Update slide index
    this.setState({ slideIndex: currentSlide });
    this.setState({ dragSwipCarousel: true });
    // Dispatch slide position related events
    let totalSlides = this.carousel ? this.carousel.state.slideCount : -1;
    if (this.props.data.lessonSteps.length > 0) {
      totalSlides--;
    }
    if (
      currentSlide === 0 &&
      this.props.instructions.navigationBackStepId === -1
    ) {
      this.props.dispatch(beginningSlide());
    } else if (currentSlide === this.props.guide.data.finalStep) {
      this.props.dispatch(
        endSlide(this.props.generalContext.appName, this.props.data.projectId)
      );
    } else {
      this.props.dispatch(
        slide(this.props.generalContext.appName, this.props.data.projectId)
      );
    }

    // Update slide data
    if (
      currentSlide > this.introSlidesNumber - 1 &&
      this.props.data.lessonSteps[currentSlide - this.introSlidesNumber]
    ) {
      let currStep =
        this.props.data.lessonSteps[currentSlide - this.introSlidesNumber];
      if (currStep.type === 6 || currStep.type === 7) {
        this.setState({ dragSwipCarousel: false });
      }
      this.props.dispatch(
        updateStepData(
          currStep.stepId,
          currentSlide - this.introSlidesNumber,
          currStep.type,
          currStep.stepGroup,
          this.props.data.lessonSteps.length,
          this.props.guide.data.allSteps.length,
          this.props.instructions.hideEnd,
          currStep.title,
          currStep.text,
          currStep.extraData,
          currStep.isDominantText
        )
      );
      ProjectAnalyticsHelper.startRecording(currStep.stepId);
    } else {
      this.props.dispatch(
        updateStepData(-1, -1, -1, "", 0, 0, false, "", "", null, false)
      );
      ProjectAnalyticsHelper.stopRecording();
    }
  };

  buildCarousel = () => {
    let materialsBlock = null;
    let stepsBlock = null;
    let cameraBlock = null;
    let allBlocks = [];

    if (
      ((this.props.data.materials && this.props.data.materials.length > 0) ||
        this.props.data.materialsImageUrl) &&
      !this.props.instructions.hideStart &&
      this.props.instructions.navigationBackStepId === -1
    ) {
      materialsBlock = (
        <div className="instructions-slide" key="materialsBlock">
          <StepMaterials
            className="instructions-slide"
            initOnLoad={true}
            imageUrl={this.props.data.materialsImageUrl}
            materialsImageUrl={this.props.data.materialsImageUrl}
            materials={this.props.data.materials}
            materialsText={this.props.data.materialsText}
          />
        </div>
      );
    }

    if (this.props.data.lessonSteps && this.props.data.lessonSteps.length > 0) {
      stepsBlock = this.props.data.lessonSteps.map((item) => (
        <div
          className="instructions-slide"
          style={{
            backgroundColor: this.props.themeContext.stepBackgroundColor,
          }}
          key={item.stepId + item.url}
        >
          <Step
            data={item}
            total={this.props.data.lessonSteps.length}
            hasCustomResources={this.props.data.hasCustomResources}
            appName={this.props.data.appName}
            fullMode={this.props.fullMode}
            openTip={this.openTip}
          />
        </div>
      ));
    }

    if (
      (this.props.data.addPhotoStep ||
        this.props.themeContext.alwaysEnableCamera === "true") &&
      !this.props.instructions.hideEnd
    ) {
      cameraBlock = <TakePhoto />;
    }

    if (
      materialsBlock &&
      materialsBlock !== "" &&
      !this.props.instructions.hideStart
    ) {
      allBlocks.push(materialsBlock);
    }
    if (stepsBlock && stepsBlock !== "") {
      allBlocks.push(stepsBlock);
    }
    if (cameraBlock) {
      allBlocks.push(cameraBlock);
    }

    this.setState({ carouselBlocks: allBlocks, stepIndex: 0 });
  };

  render() {
    return (
      <div tabIndex="0" style={{ width: "100%", height: "100%" }}>
        <Box
          display="flex"
          flexDirection="column"
          flexGrow={1}
          style={{ height: "100%" }}
        >
          {this.props.displayModeContext.portrait ? (
            <Box>
              <GuideTopbar
                goBack={this.props.goBack}
                close={this.props.close}
              />
            </Box>
          ) : null}
          <Box display="flex" flexGrow={1} style={{ display: "flex", flex: 1 }}>
            <Carousel
              ref={(ref) => (this.carousel = ref)}
              slideIndex={this.state.slideIndex}
              disableAnimation={true}
              beforeSlide={this.onSlideChange}
              swiping={this.state.dragSwipCarousel}
              dragging={this.state.dragSwipCarousel}
              disableEdgeSwiping={true}
              withoutControls={true}
              edgeEasing={null}
              decorators={null}
              enableKeyboardControls={false}
              speed={200}
            >
              {this.state.carouselBlocks}
            </Carousel>
          </Box>
          <Box
            style={{ display: this.state.showNavigation ? "block" : "none" }}
          >
            <GuideNavigation
              guideId={this.props.guide.data.projectId}
              nextSlide={this.nextSlide}
              prevSlide={this.prevSlide}
              showProgressbarPercentage={
                this.props.guide.data.showProgressbarInPercentages
              }
              hideContactUs={this.props.guide.data.hideContactUs}
              appName={this.props.generalContext.appName}
              fullMode={this.props.fullMode}
            />
          </Box>
          <SendFormMessage
            successTitle={this.props.data.successFormTitle}
            successText={this.props.data.successFormText}
          />
        </Box>
        <GalleryTip />
        <TextTip />
        <FormTip />
        {this.props.fullMode ? (
          <ContactForm guideId={this.props.guide.data.projectId} />
        ) : null}
        <Finish
          guideName={this.props.data.name}
          finishImage={this.props.finishImage}
          finishTitle={this.props.data.finishTitle}
          finishtext={this.props.data.finishText}
          nextGuideId={this.props.data.nextProjectId}
          nextGuideImageUrl={this.props.data.nextProjectImageUrl}
          nextGuideName={this.props.data.nextProjectName}
          fullMode={this.props.fullMode}
        />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    instructions: state.instructions,
    guide: state.guide,
  };
}

function matchDispatchToProps(dispatch) {
  return { dispatch };
}

export default withGeneralContext(
  withDisplayModeContext(
    withThemeContext(
      connect(mapStateToProps, matchDispatchToProps)(Instructions)
    )
  )
);
