import React, { Component } from "react";
import { connect } from "react-redux";
import "assets/styles/splitStep.scss";
import { getUnitLabel } from "../../../../../utils/utils";
import { Typography } from "@material-ui/core";

class MaterialsImageItem extends Component {
  render() {
    let itemText = `${this.props.item.quantity} ${getUnitLabel(
      this.props.item
    )} x ${this.props.item.name}`;

    return (
      <div
        className="materials-image-item"
        style={{
          width: this.props.hasimagecovermaterial ? "70px" : "140px",
          marginRight: this.props.hasimagecovermaterial ? "15px" : "18px",
          marginBottom: this.props.hasimagecovermaterial ? "15px" : "36px",
        }}
      >
        <div
          className="materials-image-item-image"
          style={{
            backgroundImage: "url(" + this.props.item.imageUrl + ")",
            backgroundColor: this.props.backgroundColor,
            color: this.props.textColor,
            width: this.props.hasimagecovermaterial ? "70px" : "140px",
            height: this.props.hasimagecovermaterial ? "70px" : "140px",
          }}
        />

        {this.props.item.quantity && this.props.item.quantity > 0 ? (
          <div className="materials-image-item-text direction">{itemText}</div>
        ) : null}
      </div>
    );
  }
}

function matchDispatchToProps(dispatch) {
  return { dispatch };
}

function mapStateToProps() {
  return {};
}

export default connect(
  mapStateToProps,
  matchDispatchToProps
)(MaterialsImageItem);
