import React from "react";

import styles from "./ProgressBubble.module.css";
import { withThemeContext } from "app/context/withThemeContext";
import { connect } from "react-redux";

class ProgressBubble extends React.Component {
  render() {
    const { value } = this.props;

    return (
      <>
        {value && value !== "" ? (
          <div
            className={styles.container}
            style={{
              backgroundColor: this.props.themeContext.progressBarColor,
            }}
          >
            <span>{value}</span>
          </div>
        ) : null}
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    stepInfo: state.step,
  };
}

function matchDispatchToProps(dispatch) {
  return { dispatch };
}

export default withThemeContext(
  connect(mapStateToProps, matchDispatchToProps)(ProgressBubble)
);
