import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withThemeContext } from 'app/context/withThemeContext';
import { withGeneralContext } from 'app/context/withGeneralContext';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { CheckIcon } from 'app/components/svg/CheckIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { changeLanguage } from './actions';
import { saveToStorage, getFromStorage } from 'app/utils/storage';
import { startGuide } from 'app/components/main/guide/actions';

class LanguagesMenu extends Component {
  changeLanguage = language => {
    const storageData = getFromStorage("guideStatus");
    saveToStorage('currentStepId',
      JSON.stringify({
        index: this.props.generalContext.currentStepId,
        guideStarted: storageData ? JSON.parse(storageData) : false
      })
    );


    this.props.openLanguagesMenu(false);
    this.props.dispatch(changeLanguage(language));
  };

  render() {
    return (
      <Menu
        anchorEl={this.props.anchor}
        disableScrollLock={true}
        keepMounted
        PaperProps={{
          style: {
            backgroundColor: this.props.themeContext.menuBackgroundColor,
            color: this.props.themeContext.menuTextColor,
            boxShadow: '0 5px 40px 0 rgba(0, 0, 0, 0.2)'
          }
        }}
        anchorOrigin={
          !this.props.downMenu
            ? { vertical: 'top', horizontal: 'center' }
            : { vertical: 'top', horizontal: 'left' }
        }
        transformOrigin={
          !this.props.downMenu
            ? { vertical: 'bottom', horizontal: 'center' }
            : { vertical: 'top', horizontal: 'left' }
        }
        open={this.props.isOpened}
        onClose={() => this.props.openLanguagesMenu(false)}
      >
        {this.props.generalContext.languages &&
          this.props.generalContext.languages.length > 0 ? (
          this.props.generalContext.languages.map(item => (
            <MenuItem
              button
              key={item.languageCode}
              onClick={() => this.changeLanguage(item.languageCode)}
              style={{ padding: 0, paddingLeft: 14, paddingRight: 14 }}
            >
              <ListItemText
                primary={item.languageName}
                style={{ paddingRight: 30 }}
              />
              {this.props.generalContext.language === item.languageCode ? (
                <CheckIcon
                  color={this.props.themeContext.menuCheckColor}
                  style={{ float: 'right' }}
                />
              ) : null}
            </MenuItem>
          ))
        ) : (
          <MenuItem style={{ padding: 0, paddingLeft: 14, paddingRight: 14 }}>
            <ListItemText primary="Default" style={{ paddingRight: 30 }} />
            <CheckIcon
              color={this.props.themeContext.menuCheckColor}
              style={{ float: 'right' }}
            />
          </MenuItem>
        )}
      </Menu>
    );
  }
}
function mapStateToProps(state) {
  return {
    guide: state.guide,
    instructions: state.instructions
  };
}

function matchDispatchToProps(dispatch) {
  return { dispatch };
}

export default withGeneralContext(
  withThemeContext(
    connect(mapStateToProps, matchDispatchToProps)(LanguagesMenu)
  )
);
