import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withThemeContext } from 'app/context/withThemeContext';
import 'assets/styles/videoStep.scss';
import { withDisplayModeContext } from 'app/context/withDisplayModeContext';
import 'rc-slider/assets/index.css';
import Box from '@material-ui/core/Box';
import StepOverlays from './overlays/StepOverlays';
import StepContentText from './StepContentText';
import StepContentLogo from './StepContentLogo';
import { CSSTransition } from 'react-transition-group';
import "@google/model-viewer";

class Step3DModel extends Component {

  componentDidMount() {
    this.mv.addEventListener("model-visibility", this.handleLoad);
  }

  componentWillUnmount() {
    this.mv.removeEventListener("model-visibility", this.handleLoad);
  }

  contentTextExists = () => {
    return (this.props.text !== '' || 
      (this.props.extraData && this.props.extraData.extraDataForm));
  }

  render() {

    return (
      <div className='step' style={{ background: '#ffffff' }}>
        <Box
          display="flex"
          flexDirection={(this.props.displayModeContext.portrait) ? "column" : "row"}
          style={{ height: 'calc(100% - 70px)' }}>
          <Box
            flexGrow={1}
            style={{
              position: 'relative',
              overflow: 'hidden',
              height: (this.props.displayModeContext.portrait) ? '50%' : '100%',
              width: (this.props.displayModeContext.portrait) ? '100%' : (100 - parseFloat(this.props.themeContext.stepTextContentPercentage)) + '%',
              backgroundColor: this.props.themeContext.stepBackgroundColor
            }}>
            
			  
			  <CSSTransition in={this.props.isCurrentStep} timeout={300} classNames="quick-fade-in">
              <model-viewer 
                ref={elem => this.mv = elem}
                id="model-viewer"
                src={this.props.videoUrl}
                style={{height:'100%', width:'100%'}}  
                camera-controls>
                <div id="controls">
                  <button onClick={this.playClick} className={"default-main-button"} data-color="1,0,0,1">Replay</button>
                </div>
                <div className="sk-spinner sk-spinner-wave" slot="poster">
                  <div className="sk-rect1"></div>
                  <div className="sk-rect2"></div>
                  <div className="sk-rect3"></div>
                  <div className="sk-rect4"></div>
                  <div className="sk-rect5"></div>
                </div>
              </model-viewer>
            </CSSTransition>
            <StepOverlays
              tips={this.props.tips}
              stepId={this.props.stepId}
              openAction={this.props.openAction} />
          </Box>
          {
            (this.contentTextExists()) ?
              <>
                <StepContentLogo />
                <Box
                  flexGrow={1}
                  style={{
                    backgroundColor: this.props.themeContext.stepTextBackgroundColor,
                    color: this.props.themeContext.stepTextColor,
                    height: (this.props.displayModeContext.portrait) ? '50%' : '100%',
                    width: (this.props.displayModeContext.portrait) ? '100%' : this.props.themeContext.stepTextContentPercentage + '%',
                  }}>
                  <div style={{ height: '100%' }}>
                    <StepContentText
                      isCurrentStep={this.props.isCurrentStep}
                      stepId={this.props.stepId}
                      extraData={this.props.extraData}
                      text={this.props.text}
                      contentTips={this.props.contentTips}
                      openAction={this.props.openAction} />
                  </div>
                </Box>
              </> : null
          }
        </Box>
      </div>
    );
  }

  handleLoad() {
    var modelViewer = document.getElementById('model-viewer');
    var extremelySmallIntervalTime = 0.05;
    var j=0;
    var annimNr = 0;
    modelViewer.play();
    
    setInterval(function(){
      if(modelViewer.currentTime > 0 && modelViewer.currentTime < (extremelySmallIntervalTime - 0.01)){ 
        ++j;
        if ( j > 0 ){
          if(annimNr >=  (modelViewer.availableAnimations.length - 1)){
            annimNr = 0;
            modelViewer.pause();
          } else {
            annimNr = annimNr + 1;
          }
          j = 0;
          modelViewer.animationName = modelViewer.availableAnimations[annimNr];
        }
     
      }else if(modelViewer.currentTime === 0)
      {
        modelViewer.play(modelViewer.availableAnimations[annimNr]);
      }
    }, extremelySmallIntervalTime*1000);
  }

  playClick() {
    document.getElementById('model-viewer').play();
  }
}


function mapStateToProps(state) {
  return {
    videostep: state.step
  };
}

function matchDispatchToProps(dispatch) {
  return { dispatch };
}

export default
  withThemeContext(
    withDisplayModeContext(
      connect(mapStateToProps, matchDispatchToProps)(Step3DModel)));
