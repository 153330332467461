import React, { Component } from "react";
import { connect } from "react-redux";
import { withThemeContext } from "app/context/withThemeContext";
import { withDisplayModeContext } from "app/context/withDisplayModeContext";
import { withGeneralContext } from "app/context/withGeneralContext";
import { init, getData, startGuide, openGuideInNewTab } from "./actions";
import Instructions from "app/components/main/instructions/Instructions";
import Intro from "app/components/instructions/intro/Intro";
import SmallIntro from "app/components/instructions/intro/SmallIntro";
import "assets/styles/guide.scss";
import "assets/styles/app.scss";
import ProjectAnalyticsHelper from "app/helpers/projectAnalyticsHelper";
import { getLanguageQueryVariable } from "app/utils/utils";
import {
  getFromStorage,
  removeFromStorage,
  saveToStorage,
} from "app/utils/storage";

class Guide extends Component {
  constructor(props) {
    super(props);
    const storageData = getFromStorage("currentStepId");
    const currentStepIndex = storageData ? JSON.parse(storageData).index : 0;
    const guideStarted = storageData
      ? JSON.parse(storageData).guideStarted
      : false;

    this.state = {
      showStart: true,
      currentStepId: currentStepIndex,
      hideIntro: guideStarted,
    };
    removeFromStorage("currentStepId");
  }
  componentDidMount() {
    // Init project time analytics service
    ProjectAnalyticsHelper.init(
      this.props.generalContext.appName,
      this.props.projectid
    );
    this.props.dispatch(init(this.props.generalContext.appName));

    // Get data and change language if needed
    if (this.props.projectid) {
      if (!this.props.guide.loadedFromStorage) {
        this.props.dispatch(
          getData(
            this.props.generalContext.appName,
            this.props.projectid,
            getLanguageQueryVariable()
          )
        );
      }
    }

    // Hide start if we have an initial step
    if (this.props.stepid && this.props.stepid > 0) {
      this.props.dispatch(startGuide(true));
    }
  }
  hideIntroComponent = (value) => {
    this.setState({
      hideIntro: false,
      currentStepId: value,
    });
  };

  start = () => {
    this.props.dispatch(startGuide(true));
    saveToStorage("guideStatus", true);
  };

  backToStart = () => {
    this.props.dispatch(startGuide(false));
    removeFromStorage("guideStatus");
  };

  openNewTab = () => {
    this.props.dispatch(openGuideInNewTab(this.props.guide.data.projectId));
  };

  render() {
    if (
      this.props.themeContext.hideContent !== undefined &&
      this.props.themeContext.hideContent === "true"
    ) {
      return <div></div>;
    }

    return (
      <div className="full-height">
        {this.props.guide.loading ? (
          <div id="mpreload">
            <span></span>
            <span></span>
            <span></span>
            <span></span>
          </div>
        ) : this.props.guide.error ? (
          <div></div>
        ) : (
          <div className="full-height">
            <div className="guide-wrapper">
              {this.props.guide.data.infoImageUrl !== "" &&
              !this.props.guide.data.isHideIntro &&
              !this.props.guide.data.hideIntroductionScreen &&
              !this.props.guide.started &&
              !this.state.hideIntro ? (
                <Intro
                  imageurl={this.props.guide.data.infoImageUrl}
                  name={this.props.guide.data.name}
                  subtitle={this.props.guide.data.subTitle}
                  description={this.props.guide.data.description}
                  timetomake={this.props.guide.data.timeToMake}
                  hideTotalStepsNr={this.props.guide.data.hideTotalStepsNr}
                  stepsNumber={this.props.guide.data.allSteps.length}
                  startButtonTitle={this.props.guide.data.startButtonTitle}
                  goBack={this.props.goBack}
                  close={this.props.close}
                  openNewTab={this.openNewTab}
                  start={this.start}
                  fullMode={this.props.fullMode}
                />
              ) : null}
              <Instructions
                data={this.props.guide.data}
                initialstepid={
                  this.state.currentStepId
                    ? this.state.currentStepId
                    : this.props.stepid
                }
                isUserFinishImage={this.props.guide.isUserFinishImage}
                finishImage={this.props.guide.finishImage}
                language={this.props.generalContext.language}
                fullMode={this.props.fullMode}
                backToStart={
                  this.props.guide.data.infoImageUrl !== "" &&
                  !this.props.guide.data.isHideIntro &&
                  !this.props.guide.data.hideIntroductionScreen
                    ? this.backToStart
                    : null
                }
                goBack={this.props.goBack}
                close={this.props.close}
                hideIntro={this.hideIntroComponent}
              />
            </div>
            {this.props.displayModeContext.small &&
            this.props.themeContext.disableMinimalMode !== "true" ? (
              <SmallIntro
                imageurl={
                  this.props.guide.data.infoImageUrl !== ""
                    ? this.props.guide.data.infoImageUrl
                    : this.props.guide.data.sectionImageUrl
                }
                name={this.props.guide.data.name}
                subtitle={this.props.guide.data.subtitle}
                description={this.props.guide.data.description}
                timetomake={this.props.guide.data.timeToMake}
                hideTotalStepsNr={this.props.guide.data.hideTotalStepsNr}
                stepsNumber={this.props.guide.data.allSteps.length}
              />
            ) : null}
          </div>
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    guide: state.guide,
    instructions: state.instructions,
  };
}

function matchDispatchToProps(dispatch) {
  return { dispatch };
}

export default withGeneralContext(
  withDisplayModeContext(
    withThemeContext(connect(mapStateToProps, matchDispatchToProps)(Guide))
  )
);
