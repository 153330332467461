import React, { Component } from "react";
import { withThemeContext } from "app/context/withThemeContext";
import { withGeneralContext } from "app/context/withGeneralContext";
import { withDisplayModeContext } from "app/context/withDisplayModeContext";
import { LeftArrowIcon } from "app/components/svg/LeftArrowIcon";
import "assets/styles/guideNavigation.scss";

class PrevButton extends Component {
  render() {
    return (
      <button
        onClick={this.props.click}
        className="navigation-prev-button"
        style={{
          visibility: this.props.visible ? "" : "hidden",
          backgroundColor: this.props.themeContext.controllerBackgroundColor,
          borderColor: this.props.themeContext.buttonBackgroundColor,
        }}
      >
        <LeftArrowIcon color={this.props.themeContext.controllerTextColor} />
      </button>
    );
  }
}

export default withDisplayModeContext(
  withGeneralContext(withThemeContext(PrevButton))
);
