import React, { Component } from "react";
import { connect } from "react-redux";
import { updateStep } from "./actions";
import "assets/styles/progressbar.scss";

const withProgressBar = (WrappedProgressbarComponent) => {
  class ProgressBarBase extends Component {

    state = {
      value: 0,
      tempStepId: null,
      inProgress: false,
    };

    componentDidMount() {
      const initialValue =
        (100 * this.props.stepInfo.stepIndex) / this.props.stepInfo.total;
      this.setState({ value: initialValue });

      document.addEventListener("mousemove", this.onMouseMove);
      document.addEventListener("mouseup", this.onMouseUp);
      document.addEventListener("touchmove", this.onTouchMove);
      document.addEventListener("touchend", this.onTouchEnd);
    }

    componentWillUnmount() {
      document.removeEventListener("mousemove", this.onMouseMove);
      document.removeEventListener("mouseup", this.onMouseUp);
      document.removeEventListener("touchmove", this.onTouchMove);
      document.removeEventListener("touchend", this.onTouchEnd);
    }

    componentDidUpdate(prevProps) {
      if (
        prevProps.stepInfo.stepIndex !== this.props.stepInfo.stepIndex ||
        prevProps.stepInfo.total !== this.props.stepInfo.total
      ) {
        const newValue =
          (100 * this.props.stepInfo.stepIndex) / this.props.stepInfo.total;
        this.setState({ value: newValue });
      }
    }

    onMouseMove = (event) => {
      event.preventDefault();
      if (this.state.inProgress) {
        let percentage = this.calculatePercentage(event.pageX);
        const newStepId = this.calculateStepId(percentage);
        this.setState({ value: percentage, tempStepId: newStepId });
      }
    };

    onMouseDown = (event) => {
      if (this.isTouchDevice()) return;
      event.preventDefault();
      const percentage = this.calculatePercentage(event.pageX);
      const newStepId = this.calculateStepId(percentage);
     
      this.setState({
        inProgress: true,
        tempStepId: newStepId,
        value: percentage,
      });
    };

    onMouseUp = () => {
      const { tempStepId } = this.state;
      
      if (tempStepId !== null && tempStepId !== this.props.stepInfo.stepId) {
        this.props.dispatch(updateStep(tempStepId - 1));
      }
      
      this.setState({ inProgress: false, tempStepId: null });
    };

    isTouchDevice = () => {
      return 'ontouchstart' in window || navigator.maxTouchPoints;
    }

    onTouchStart = (event) => {
      if (!this.isTouchDevice()) return;
      const percentage = this.calculatePercentage(event.touches[0].clientX);
      const newStepId = this.calculateStepId(percentage);
      
      this.setState({
        inProgress: true,
        tempStepId: newStepId,
        value: percentage,
      });
     
      
    };

    onTouchMove = (event) => {
      if(this.state.inProgress) {
        const percentage = this.calculatePercentage(event.touches[0].clientX);
        const newStepId = this.calculateStepId(percentage);
        
        this.setState({ value: percentage, tempStepId: newStepId });
      }
    };

    onTouchEnd = (event) => {
      const { tempStepId } = this.state;

      if (tempStepId !== null && tempStepId !== this.props.stepInfo.stepId) {
        this.props.dispatch(updateStep(tempStepId - 1));
      }

      this.setState({ inProgress: false, tempStepId: null });
    };

    calculatePercentage = (positionX) => {
      const { left, width } = this.progressBar.getBoundingClientRect();
      const percentage = Math.min(100, Math.max(0, ((positionX - left) / width) * 100));
      return percentage;
    };

    calculateStepId = (percentage) => {
      const stepSize = 100 / this.props.stepInfo.total;
      return Math.min(this.props.stepInfo.total, Math.floor(percentage / stepSize) + 1);
    };

    render() {
      return (
        <div onMouseDown={this.onMouseDown} onTouchStart={this.onTouchStart}>
          <div
            ref={(progressBar) => {
              this.progressBar = progressBar;
            }}
          >
            <WrappedProgressbarComponent
              progressValue={this.state.value}
              inProgress={this.state.inProgress}
              tempStepId={this.state.tempStepId}
              showPercentage={this.props.showPercentage}
            />
          </div>
        </div>
      );
    }
  }

  const mapStateToProps = (state) => ({
    stepInfo: state.step,
  });

  return connect(mapStateToProps)(ProgressBarBase);
};

export default withProgressBar;
